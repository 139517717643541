<template>
  <b-card>
    <b-row>
      <h6 class="section-label mx-1 mb-2 text-info">Parametres de la monnaie</h6>
    </b-row>
    <b-form class="mt-2">
      <b-row>
        <b-col sm="4">
          <b-form-group label="Monnaie de compabilité" label-for="currency">
            <b-form-input
              v-model="currency"
              placeholder="USD"
              name="currency"
              readonly
            />
          </b-form-group>
        </b-col>

        <b-col sm="4">
          <b-form-group label="Symbole de Monnaie" label-for="currency_symbol">
            <b-form-input
              v-model="currency_symbol"
              placeholder="$"
              name="currency_symbol"
              readonly
            />
          </b-form-group>
        </b-col>

        <b-col sm="4">
          <b-form-group label="Format des montants" label-for="amount_format">
            <b-form-input
              v-model="amount_format"
              placeholder="X.XX | X,XX"
              name="amount_format"
            />
          </b-form-group>
        </b-col>

        <b-col sm="4">
          <b-form-group
            label="Nombre des decimaux"
            label-for="amount_format_decimal"
          >
            <b-form-input
              v-model="amount_format_decimal"
              placeholder="2"
              name="amount_format_decimal"
            />
          </b-form-group>
        </b-col>

        <b-col sm="4">
          <b-form-group
            label="Séparateur des miliers"
            label-for="mile_separator"
          >
            <b-form-input
              v-model="mile_separator"
              placeholder="Ex: . , Vide = séparer les miliers par espaces"
              name="mile_separator"
            />
          </b-form-group>
        </b-col>

        <b-col sm="4">
          <b-form-group
            label="Format de la quantité"
            label-for="quantity_format"
          >
            <b-form-input
              v-model="quantity_format"
              placeholder="X.XX | X,XX"
              name="quantity_format"
            />
          </b-form-group>
        </b-col> 
      </b-row>
    </b-form>
  </b-card>
</template>

<script>
import {
  BButton,
  BForm,
  BFormGroup,
  BFormInput,
  BRow,
  BCol,
  BCard,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import { useInputImageRenderer } from "@core/comp-functions/forms/form-utils";
import { ref } from "@vue/composition-api";

export default {
  components: {
    BButton,
    BForm,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BCard,
  },
  directives: {
    Ripple,
  },
  props: {
    currencyData: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      currency: "USD",
      currency_symbol: "$",
      amount_format: "",
      amount_format_decimal: "2",
      quantity_format: "",
      mile_separator: "",
    };
  },
  methods: {
    async postCurrencyForm() {
      var formData = new FormData();
      const currencyArray = [
        { currency: this.$props.currency },
        { currency_symbol: this.$props.currency_symbol },
        { amount_format: this.$props.amount_format },
        { amount_format_decimal: this.$props.amount_format_decimal },
        { quantity_format: this.$props.quantity_format },
        { mile_separator: this.$props.mile_separator },
      ];
      formData.append("currency", currencyArray);

      await myApi
        .put("accountingfile/" + this.$props.currencyData.id, formData)
        .then((response) => {
          if (response.status == 200) {
            this.$swal({
              title: "Fin de l'opération",
              text: response.data.message,
              icon: "success",
              timer: 10000,
              showCancelButton: true,
              confirmButtonText: "Continuer",
              cancelButtonText: "Annuler",
              customClass: {
                confirmButton: "btn btn-primary",
                cancelButton: "btn btn-outline-warning ml-1",
              },
              buttonsStyling: false,
            });
          } else {
            this.$swal({
              title: "Erreur Survenu",
              text: response.data.message,
              icon: "error",
              timer: 10000,
              showCancelButton: false,
              confirmButtonText: "Continuer",
              cancelButtonText: "Annuler",
              customClass: {
                confirmButton: "btn btn-warning",
                cancelButton: "btn btn-outline-error ml-1",
              },
              buttonsStyling: false,
            });
          }
        })
        .catch((error) => {
          this.$swal({
            title: "Erreur Survenu",
            text: error.message,
            icon: "error",
            timer: 10000,
            showCancelButton: false,
            confirmButtonText: "Continuer",
            cancelButtonText: "Annuler",
            customClass: {
              confirmButton: "btn btn-warning",
              cancelButton: "btn btn-outline-error ml-1",
            },
            buttonsStyling: false,
          });
        });
    },
    resetForm() {
      this.optionsLocal = JSON.parse(JSON.stringify(this.currencyData));
    },
  },
  setup() {
    const refInputEl = ref(null);
    const previewEl = ref(null);

    const { inputImageRenderer } = useInputImageRenderer(refInputEl, previewEl);

    return {
      refInputEl,
      previewEl,
      inputImageRenderer,
    };
  },
};
</script>
